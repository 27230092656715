import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, NavLink, HashRouter } from "react-router-dom";
import {
  Image,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Pressable,
  SafeAreaView,
} from "react-native";
import { List, Avatar, Portal } from "react-native-paper";
const logo = require("../assets/images/bermadLogo.png");
const blogo = require("../assets/images/blogo.png");
const logodash = require("../assets/images/home.png");
const logoAlert = require("../assets/images/alerts.png");
import AsyncStorage from "@react-native-async-storage/async-storage";

const logoSchedule = require("../assets/images/schedule.png");

const logoprofile = require("../assets/images/profile.png");
const logoaccess = require("../assets/images/report.png");
const logomonitor = require("../assets/images/monitor.png");

const logomaster = require("../assets/images/master.png");
import Icon from "react-native-vector-icons/FontAwesome";
import IconMenu from "react-native-vector-icons/Ionicons";
import IconArrow from "react-native-vector-icons/Feather";
// import { createDrawerNavigator } from '@react-navigation/drawer';

import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";

import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { baseUrl, baseUrlimage, postData } from "../networking/api";
import { ListItemBase } from "react-native-elements/dist/list/ListItemBase";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const [expandedProfile, setExpandedProfile] = useState(true);
  const [expandedMaster, setExpandedMaster] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [projectId, setProjectId] = useState("");
  var idProject = useSelector((state) => state.idUpdating);

  const navigation = useNavigation();
  const Drawer = createDrawerNavigator();

  const handlePressProfile = () => setExpandedProfile(!expandedProfile);
  const handlePressMaster = () => setExpandedMaster(!expandedMaster);
  const [states, setStates] = useState("Open Home Screen");
  const [indexval, setindexval] = useState(0);
  const [drawerIndex, setdrawerIndex] = useState(0);
  const [activemain, setactivemain] = useState(0);
  const [menuBar, setMenuBar] = useState([
    {
      childMenuId: 0,
      groupName: "Dashboard",
      activeTintColor: "#FF6F00",
      logo: logodash,
      isSelected: false,
    },
    {
      childMenuId: 1,
      logo: logoprofile,
      drawerLabel: [
        {
          Dashboard: "Device",
          nav: "DeviceProfile",
        },
        {
          Dashboard: "SubZone",
          nav: "SubzoneProfile",
        },
        {
          Dashboard: "Zone",
          nav: "ZoneProfile",
        },
        {
          Dashboard: "Project",
          nav: "ProjectProfile",
        },
        {
          Dashboard: "Farmer",
          nav: "FarmerProfile",
        },
      ],
      groupName: "Profile",
      activeTintColor: "#FF6F20",
      isSelected: false,
    },
    {
      childMenuId: 2,
      childMenuName: "Country",
      logo: logomaster,
      drawerLabel: [
        {
          Dashboard: "Country",
          nav: "Country",
        },
        {
          Dashboard: "State",
          nav: "State",
        },
        {
          Dashboard: "City",
          nav: "City",
        },
        {
          Dashboard: "Tahasil",
          nav: "Tahasil",
        },
        {
          Dashboard: "Village",
          nav: "Village",
        },
        {
          Dashboard: "Document Type",
          nav: "DocumentType",
        },
        {
          Dashboard: "User Type",
          nav: "UserType",
        },
        {
          Dashboard: "User Master",
          nav: "UserMaster",
        },
        {
          Dashboard: "Project Type",
          nav: "ProjectType",
        },
        {
          Dashboard: "Project level",
          nav: "ProjectLevel",
        },
        {
          Dashboard: "Omega Device",
          nav: "OmegaDevice",
        },
      ],
      groupName: "Master",
      activeTintColor: "#FF6F00",
      isSelected: false,
    },

    {
      childMenuId: 3,
      childMenuName: "Scheduler",
      logo: logoSchedule,
      drawerLabel: [
        {
          Dashboard: "Add Schedule",
          nav: "ScheduleProgram",
        },
        {
          Dashboard: "Upload Schedule",
          nav: "UploadSchedule",
        },
        {
          Dashboard: "Download Schedule",
          nav: "DownloadSchedule",
        },
      ],
      groupName: "Schedular",
      activeTintColor: "#FF6F00",
      isSelected: false,
    },

    {
      childMenuId: 4,
      groupName: "Alerts",
      activeTintColor: "#FF6F00",
      logo: logoAlert,
      isSelected: false,
    },
    {
      childMenuId: 5,
      childMenuName: "Access",
      logo: logoaccess,
      drawerLabel: [
        {
          Dashboard: "User Access",
          nav: "UserAccess",
        },
        {
          Dashboard: "Project Access",
          nav: "ProjectAccess",
        },
      ],
      groupName: "Access",
      activeTintColor: "#FF6F00",
      isSelected: false,
    },

    {
      childMenuId: 6,
      childMenuName: "Monitoring",
      logo: logomonitor,
      drawerLabel: [
        {
          Dashboard: "Grid View",
          nav: "GridView",
        },
        {
          Dashboard: "Map View",
          nav: "MapView",
        },
      ],
      groupName: "Monitoring",
      activeTintColor: "#FF6F00",
      isSelected: false,
    },
  ]);

  let lastGroupName = "";
  let newGroup = true;
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShow, setShouldShow] = useState(true);
  const [MenuNameData, setMenuNameData] = useState([]);
  const [ChildMenuNameData, setChildMenuNameData] = useState([]);
  const [userType, setUserType] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [positions, setPositions] = useState({});

  const itemRefs = useRef <any>({});
  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
    }, [idProject])
  );
  const getAdminData = async () => {
    try {
      const [jsonValue, projValue] = await Promise.all([
        AsyncStorage.getItem("userData"),
        AsyncStorage.getItem("projectData"),
      ]);

      if (projValue) {
        const userDetailsresponse = JSON.parse(jsonValue);
        setProjectId(projValue);
        setUserType(userDetailsresponse.user_type);
        getAccessMasterDetails(userDetailsresponse.user_type);
      } else if (jsonValue) {
        const userDetailsresponse = JSON.parse(jsonValue);
        setUserType(userDetailsresponse.user_type);
        setProjectId(userDetailsresponse.project_selected);
        getAccessMasterDetails(userDetailsresponse.user_type);
      }
    } catch (error) {
      console.error("Error while getting admin data:", error);
    }
  };

  const getAccessMasterDetails = async (userTypeId) => {
    setIsLoading(true);

    try {
      const UserTypeData = JSON.stringify({ user_type: userTypeId });
      console.log("UserTypeData", UserTypeData);

      const UserTypeResponse = await postData(UserTypeData, "masters/getUserTypeWiseAccess");
      const code = UserTypeResponse.code;

      setIsLoading(false);

      if (code === 1) {
        console.log("sidebar menubar", UserTypeResponse.data);
        setShouldShow(false);
        setMenuNameData(UserTypeResponse.data);

        const tempArr = UserTypeResponse.data.map((item) => item.childMenu);
        setChildMenuNameData(tempArr);

        // You can remove the following lines as they don't seem to be used.
        // setUserTypeByName("");
        // textUserType.current = "";
      } else {
        // Handle other error cases here if needed.
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };


  const handleMouseEnter = (item) => {
    if (itemRefs.current[item.menu_desc]) {
      itemRefs.current[item.menu_desc].measure(
        (fx, fy, width, height, px, py) => {
          setPositions((prev) => ({
            ...prev,
            [item.menu_desc]: { x: px, y: py, width, height },
          }));
        }
      );
    }
    setHoveredItem(item.menu_desc);
  };

  return (
    <SafeAreaView
      style={{
        width: showMenu ? 180 : 50,
        height: "100%",
        alignItems: "center",
        backgroundColor: "#006EB9",
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={showMenu ? true : false}
        persistentScrollbar={true}
      >
        <DrawerContentScrollView
          contentContainerStyle={{ paddingBottom: 40 }}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={{
              backgroundColor: "#006EB9",
              marginVertical: 25,
            }}
          >
            <TouchableOpacity
              style={{ marginBottom: 14 }}
              onPress={() => navigation.navigate("Dashboard")}
            >
              <Image
                source={showMenu ? logo : blogo}
                style={{
                  resizeMode: "contain",
                  width: "90%",
                  height: "100%",
                  padding: 18,
                  marginBottom: 25,
                  alignSelf: "center",
                }}
              />
            </TouchableOpacity>
          </View>

          {showMenu
            ? MenuNameData.map((item, index) => {
              // console.log("item menu", item);
              var menuImg =
                baseUrlimage + "uploads/menu_image/" + item.logo_image;
              if (lastGroupName !== item.menu_desc) {
                newGroup = true;
                lastGroupName = item.menu_desc;
              } else newGroup = false;

              return (
                <>
                  {newGroup ? (
                    <View style={{ marginLeft: 4 }}>
                      {item.listing_access === 1 && userType !== 1 ? (
                        <>
                          <Pressable
                            onPress={() => {
                              if (item.menu_desc == "Dashboard") {
                                navigation.navigate("Dashboard");
                              }
                              if (item.menu_desc == "Alerts") {
                                navigation.navigate("Alerts");
                              }
                              if (item.menu_desc == "Feedback") {
                                navigation.navigate("Feedback");
                              }
                              setactivemain(index == activemain ? null : index);
                              setPositions({})
                              setHoveredItem(null)
                            }}
                            style={{
                              marginBottom: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor:
                                index == activemain ? "#08588E" : null,
                              flex: 1,
                            }}
                          >
                            <Image
                              style={{
                                margin: 10,
                                marginLeft: 15,
                                padding: 0,
                                backgroundColor: "#34343400",
                                width: 19,
                                height: 21,
                              }}
                              source={{ uri: menuImg }}
                            />
                            <Text
                              key={item.menu_desc}
                              style={{
                                flex: 0.85,
                                color: "white",
                                marginVertical: 10,
                                marginLeft: 10,
                              }}
                            >
                              {item.menu_desc}
                            </Text>
                            {item.menu_desc == "Dashboard" ||
                              item.menu_desc == "Alerts" ||
                              item.menu_desc == "Feedback" ? (
                              <View style={{ marginHorizontal: 25 }} />
                            ) : expandedProfile && index == activemain ? (
                              <List.Icon
                                icon="minus"
                                style={{ flex: 0.15 }}
                                color="#fff"
                              />
                            ) : (
                              <List.Icon
                                icon="plus"
                                style={{ flex: 0.15 }}
                                color="#fff"
                              />
                            )}
                          </Pressable>
                        </>
                      ) : userType === 1 ? (
                        <>
                          <Pressable
                            onPress={() => {
                              if (item.menu_desc == "Dashboard") {
                                navigation.navigate("Dashboard");
                              }
                              if (item.menu_desc == "Alerts") {
                                navigation.navigate("Alerts");
                              }
                              if (item.menu_desc == "Feedback") {
                                navigation.navigate("Feedback");
                              }
                              setShowMenu(item.menu_desc !== "Dashboard" && item.menu_desc !== "Feedback" && item.menu_desc !== "Alerts" ? true : false)
                              setactivemain(index == activemain ? null : index);
                              setPositions({})
                              setHoveredItem(null)
                            }}
                            style={{
                              marginBottom: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor:
                                index == activemain ? "#08588E" : null,
                              flex: 1,
                              zIndex: 1000
                            }}
                          >
                            <Image
                              style={{
                                margin: 10,
                                padding: 0,
                                backgroundColor: "#34343400",
                                width: 19,
                                height: 21,
                              }}
                              source={{ uri: menuImg }}
                            />
                            <Text
                              key={item.menu_desc}
                              style={{
                                flex: 0.85,
                                color: "white",
                                marginVertical: 10,
                                marginLeft: 10,
                              }}
                            >
                              {item.menu_desc}
                            </Text>
                            {item.menu_desc == "Dashboard" ||
                              item.menu_desc == "Alerts" ||
                              item.menu_desc == "Feedback" ? (
                              <View style={{ marginHorizontal: 25 }} />
                            ) : expandedProfile && index == activemain ? (
                              <List.Icon
                                icon="minus"
                                style={{ flex: 0.15 }}
                                color="#fff"
                              />
                            ) : (
                              <List.Icon
                                icon="plus"
                                style={{ flex: 0.15 }}
                                color="#fff"
                              />
                            )}
                          </Pressable>
                        </>
                      ) : null}
                    </View>
                  ) : null}
                  {index == activemain &&
                    item.childMenu &&
                    item.childMenu.map((items, drawerindex) => {
                      return (
                        <DrawerItem
                          key={item.menu_id}
                          label={({ color }) => (
                            <Text style={{ marginLeft: 30, color: "white" }}>
                              {items.menu_desc}
                            </Text>
                          )}
                          activeBackgroundColor={"#009DF0"}
                          style={{
                            width: "100%",
                            marginLeft: 0,
                            alignSelf: "flex-start",
                          }}
                          focused={
                            indexval == index && drawerIndex == drawerindex
                          }
                          // labelStyle={{alignSelf:'flex-end'}}
                          // labelStyle={{alignSelf:'center'}}
                          activeTintColor={item.activeTintColor}
                          onPress={() => {
                            setindexval(index);
                            setShowMenu(false)
                            setPositions({})
                            setHoveredItem(null)
                            setdrawerIndex(drawerindex);
                            // navigation.navigate(items.nav);
                            if (items.menu_desc == "Download Schedule") {
                              window.open(
                                baseUrl + "reports/ExportProgram/" + projectId
                              );
                            } else {
                              navigation.reset({
                                index: 0,
                                routes: [{ name: items.menu_url }],
                              });
                            }

                            for (let i = 0; i < MenuNameData.length; i++) {
                              MenuNameData[i].isSelected = false;
                              // console.log(
                              // " loop menuBar[index].isSelected",
                              //   MenuNameData[i].isSelected
                              // );
                            }
                            MenuNameData[index].isSelected = true;

                            setMenuNameData(MenuNameData);
                          }}
                        />
                      );
                    })}
                </>
              );
            })
            : MenuNameData.map((item, index) => {
              // console.log("item", item);
              // console.log("userType", userType);
              var imgUrl =
                baseUrlimage + "uploads/menu_image/" + item.logo_image;
              return (
                // (item.listing_access == 1 && userType != 1) &&
                // (userType == 1) || (item.listing_access == 1) &&
                // userType != 1 &&
                <View>
                  {item.listing_access === 1 && userType !== 1 ? (
                    <>
                      <Pressable
                        ref={(ref) => (itemRefs.current[item.menu_desc] = ref)}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={() => {
                          setHoveredItem(null)
                          setPositions({})
                        }}
                        onPress={() => {
                          setHoveredItem(null)
                          setPositions({})
                          setShowMenu(true)
                        }}
                        onLayout={(event) => {
                          const { x, y, width, height } = event.nativeEvent.layout;
                          setPositions((prev) => ({
                            ...prev,
                            [item.menu_desc]: { x, y, width, height },
                          }));
                        }}
                        style={{
                          marginBottom: 10,
                          alignItems: "center",
                          width: 50,
                          backgroundColor: "#006EB9",
                        }}
                      >
                        <Image
                          style={{
                            margin: 10,
                            padding: 0,
                            backgroundColor: "#34343400",
                            width: 19,
                            height: 21,
                          }}
                          source={{ uri: imgUrl }}
                        />
                      </Pressable>
                      {hoveredItem === item.menu_desc && positions[item.menu_desc] && (
                        <Portal>
                          <View
                            style={{
                              position: "absolute",
                              left: positions[item.menu_desc].x + positions[item.menu_desc].width + 10, // Align right
                              top: positions[item.menu_desc].y + 5,
                              backgroundColor: "#006EB9",
                              padding: 10,
                              borderRadius: 8,
                            }}
                          >
                            <Text style={{ color: "white", fontSize: 12, fontWeight:"700" }}>
                              {item.menu_desc}
                            </Text>
                          </View>
                        </Portal>
                      )}
                    </>
                  ) : userType === 1 ? (
                    <>
                      <Pressable
                        ref={(ref) => (itemRefs.current[item.menu_desc] = ref)}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={() => {
                          setHoveredItem(null)
                          setPositions({})
                        }}
                        onPress={() => {
                          setHoveredItem(null)
                          setPositions({})
                          setShowMenu(true)
                        }}
                        onLayout={(event) => {
                          const { x, y, width, height } = event.nativeEvent.layout;
                          setPositions((prev) => ({
                            ...prev,
                            [item.menu_desc]: { x, y, width, height },
                          }));
                        }}
                        style={{
                          marginBottom: 10,
                          alignItems: "center",
                          width: 50,
                          backgroundColor: "#006EB9",
                        }}
                      >
                        <Image
                          style={{
                            margin: 10,
                            padding: 0,
                            backgroundColor: "#34343400",
                            width: 19,
                            height: 21,
                          }}
                          source={{ uri: imgUrl }}
                        />
                      </Pressable>
                      {console.log("positions", JSON.stringify(positions))}
                      {hoveredItem === item.menu_desc && positions[item.menu_desc] && (
                        <Portal>
                          <View
                            style={{
                              position: "absolute",
                              left: positions[item.menu_desc].x + positions[item.menu_desc].width + 10, // Align right
                              top: positions[item.menu_desc].y + 5,
                              backgroundColor: "#006EB9",
                              padding: 10,
                              borderRadius: 8,
                            }}
                          >
                            <Text style={{ color: "white", fontSize: 12, fontWeight:"700" }}>
                              {item.menu_desc}
                            </Text>
                          </View>
                        </Portal>
                      )}
                    </>
                  ) : null}
                </View>
              );
            })}
        </DrawerContentScrollView>
      </ScrollView>
      <IconArrow
        style={{
          color: "white",
          margin: 10,
          alignSelf: "flex-start",
          position: "fixed",
          bottom: 0,
        }}
        color="white"
        onPress={() => setShowMenu(!showMenu)}
        name={!showMenu ? "arrow-right-circle" : "arrow-left-circle"}
        size={30}
      />
      {/* <IconMenu style={{ color: 'white', margin: 10, alignSelf: 'flex-start', position: 'absolute', bottom: 0 }} color="white" onPress={() => setShowMenu(!showMenu)} name={!showMenu ? "menu" : "menu"} size={30} /> */}
    </SafeAreaView>
  );
}

import Header from "../navigation/header";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  ScrollView,
  ActivityIndicator,
  Switch,
  useWindowDimensions,
  Image,
  Pressable,
  Dimensions,
} from "react-native";
import DataTable from "react-data-table-component";
import { DataTable as DataTableNEW } from "react-native-paper";

import { RadioButton, Button } from "react-native-paper";
import moment from "moment";
import { TabView, SceneMap } from "react-native-tab-view";
import IconRefresh from "react-native-vector-icons/FontAwesome";

import { CheckBox } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { DatePickerModal } from "react-native-paper-dates";
import { Dropdown } from "react-native-element-dropdown";

import { Text, View } from "../../../components/Themed";

import Icon from "react-native-vector-icons/AntDesign";
import React, { useState, useRef, useEffect } from "react";
import IconCross from "react-native-vector-icons/Entypo";
import IconEdit from "react-native-vector-icons/FontAwesome";
import { baseUrl, postData } from "../../../networking/api";
import { useFocusEffect } from "@react-navigation/native";
import SuccessPopup from "../../../components/SuccessPopup";

export default function CreateFarmerProfile({ navigation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [animating, setAnimating] = useState(true);
  const [isAdd, setisAdd] = useState(false);

  const [genderchecked, setGenderChecked] = useState(1);
  const [isMarriedchecked, setMaritialChecked] = useState(1);
  const [isstatuschecked, setStatusChecked] = useState(1);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [empCode, setEmpCode] = useState("");

  const [FarmerID, setFarmerID] = useState("");
  const [isFarmerIDValid, setFarmerIDValid] = useState(false);
  const [isFarmerIDEntered, setFarmerIDEntered] = useState(false);

  const [FarmerName, setFarmerName] = useState("");
  const [isFarmerNameValid, setFarmerNameValid] = useState(false);
  const [isFarmerNameEntered, setFarmerNameEntered] = useState(false);
  const [projectId, setProjectId] = useState("");

  const [EmailId, setEmailId] = useState("");
  const [isEmailIdValid, setEmailIdValid] = useState(false);
  const [isEmailIdEntered, setEmailIdEntered] = useState(false);

  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(false);
  const [isMobileNumberEntered, setMobileNumberEntered] = useState(false);

  const [OmegaIdData, setOmegaIdData] = useState([]);
  const [OmegavalueId, setOmegaValueiD] = useState("");
  const [OmegaDetails, setOmegaDetails] = useState([]);
  const [AnalogInput, setAnalogInput] = useState([]);
  const [DigitalInput, setDigitalInput] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);

  const [OmegaIdValid, setOmegaIdValid] = useState(false);
  const [OmegaIdEntered, setOmegaIdEntered] = useState(false);

  const [CountryNameData, setCountryNameData] = useState([]);
  const [isCountryNameDataValid, setCountryNameDataValid] = useState(false);
  const [isCountryNameDataEntered, setCountryNameDataEntered] = useState(false);

  const [StateNameData, setStateNameData] = useState([]);
  const [isStateNameDataValid, setStateNameDataValid] = useState(false);
  const [isStateNameDataEntered, setStateNameDataEntered] = useState(false);

  const [CityNameData, setCityNameData] = useState([]);
  const [isCityNameDataValid, setCityNameDataValid] = useState(false);
  const [isCityNameDataEntered, setCityNameDataEntered] = useState(false);

  const [TahasilNameData, setTahasilNameData] = useState([]);
  const [isTahasilNameDataValid, setTahasilNameDataValid] = useState(false);
  const [isTahasilNameDataEntered, setTahasilNameDataEntered] = useState(false);

  const [VillageNameData, setVillageNameData] = useState([]);
  const [isVillageNameDataValid, setVillageNameDataValid] = useState(false);
  const [isVillageNameDataEntered, setVillageNameDataEntered] = useState(false);

  const [PinCode, setPinCode] = useState("");
  const [isPinCodeValid, setPinCodeValid] = useState(false);
  const [isPinCodeEntered, setPinCodeEntered] = useState(false);

  const [Address, setAddress] = useState("");
  const [isAddressValid, setAddressValid] = useState(false);
  const [isAddressEntered, setAddressEntered] = useState(false);

  const [KhasaraNumber, setKhasaraNum] = useState("");
  const [isKhasaraValid, setKhasaraValid] = useState(false);
  const [isKhasaraEntered, setKhasaraEntered] = useState(false);

  const [loginId, setloginId] = useState("");
  const [isloginIdValid, setloginIdValid] = useState(false);
  const [isloginIdEntered, setloginIdEntered] = useState(false);

  const [Password, setPassword] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isPasswordEntered, setPasswordEntered] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  // Master Id's and names
  const [countryValue, setCountryValue] = useState("");
  const [countryValId, setCountryValId] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateValId, setStateValId] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [cityValId, setCityValId] = useState("");
  const [tahasilValue, setTahasilValue] = useState("");
  const [tahasilValId, setTahasilValId] = useState("");
  const [VillageValue, setVillageValue] = useState("");
  const [VillageValId, setVillageValId] = useState("");
  const [KhasaraValue, setKhasaraValue] = useState("");
  const [KhasaraValId, setKhasaraValId] = useState("");

  //Dropdown value pickup
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [ValveName, setValveName] = useState(null);
  const [ValveIndex, setValveIndex] = useState(null);

  const [ValveId, setValveID] = useState("");

  //Error message
  const [FarmerNameError, setFarmerNameError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [mobileNumError, setMobileNumError] = useState("");
  const [countryError, seTCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [tahasilError, setTahasilError] = useState("");
  const [villageError, setVillageError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [pinError, setPinError] = useState("");
  const [khasaraError, setkhasaraError] = useState("");
  const [fieldId, setfieldId] = useState(0);

  const [fieldname, setfieldname] = useState("");
  const [Cultivated, setCultivated] = useState("");
  const [Water, setWater] = useState("");
  const [Crops, setCrops] = useState("");
  const [address, setaddress] = useState("");
  const [zone, setzone] = useState("");
  const [subZone, setSubZone] = useState("");
  const [devices, setDevice] = useState("");

  const [deviceRelationship, setdeviceRelationship] = useState(0);
  const [deviceRelationshipId, setdeviceRelationshipId] = useState();

  const [tableData, settableData] = useState([]);

  const [ProjectIdOmega, setProjectIdOmega] = useState("");
  const [CultivatedTotal, setCultivatedTotal] = useState(0);
  const [WaterTotal, setWaterTotal] = useState(0);
  const [cropsTotal, setcropsTotal] = useState(0);
  const [firstdata, setfirstdata] = useState([]);
  const [savedata, setsave] = useState(false);



  //error

  const [fieldnameError, setfieldnameError] = useState("");
  const [CultivatedError, setCultivatedError] = useState("");
  const [WaterError, setWaterError] = useState("");
  const [CropsError, setCropsError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [OmegaError, setOmegaError] = useState("");
  const [valvesError, setValvesError] = useState("");

  const updateTableData = (dataindex) => {
    setfieldId(dataindex);
    settableData([
      ...tableData,
      {
        data: {
          field_id: dataindex,
          khasara_number: fieldname,
          cultivated_area: Cultivated,
          water_demand: Water,
          crops: Crops,
          address: address,
          zone: zone,
          subZone: subZone,
          devices: devices,
          device_id: OmegavalueId,
          valve_id: ValveId,
          OmegaIdValue: OmegaIdValue,
          ValveName: ValveName,
          valve_index: ValveIndex,
          device_relationship: deviceRelationship,
          device_relationship_referance_id: deviceRelationshipId,
        },
      },
    ]);

    setfieldname(""), setCultivated("");
    setOmegaIdValue(null), setOmegaValueiD("");
    setValveName(null);
    setValveIndex(null);
    setValveID("");
    setWater("");
    setCrops("");
    setaddress("");
    setzone("");
    setSubZone("");
    setDevice("");
  };

  // console.log("tableDatatableData", tableData, firstdata);

  const [ActiveTab, setActiveTab] = useState(0);
  //Dropdown

  const dropdownRefcountry = useRef();
  const dropdownRefState = useRef();
  const dropdownRefCity = useRef();
  const dropdownRefTahasil = useRef();
  const dropdownRefVillage = useRef();
  const dropdownRefOmegaiD = useRef();

  const closeMenu = () => {
    dropdownRefcountry.current.close();
    dropdownRefState.current.close();
    dropdownRefCity.current.close();
    dropdownRefTahasil.current.close();
    dropdownRefVillage.current.close();
    dropdownRefOmegaiD.current.close();
    //return calls;
    // // console.log("on scroll close in dropdown")
  };

  useEffect(() => {
    window.addEventListener("scroll", closeMenu);
    return () => window.removeEventListener("scroll", closeMenu);
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      if (savedata) {
        // console.log("Inside save data");
        createFarmerProfileData();
      }
    }, [savedata])
  );

  useEffect(() => {
    getAllFarmerData();
    setCultivatedTotal(0);
    setWaterTotal(0);
    setcropsTotal(0);
    setsave(false);
  }, []);

  const getAllFarmerData = async () => {
    setIsLoading(true);
    try {
      var getFarmerData = JSON.stringify({
        module: "farmer_master",
        relation: [],
      });
      const FarmerNameResponse = await postData(
        getFarmerData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = FarmerNameResponse.code;
      if (code == 1) {
        // console.log("FarmerNameResponse list====>", FarmerNameResponse.data);
        // settableData(FarmerNameResponse.data)
      } else {
        setIsLoading(false);
        //alert(FarmerNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "DigitalOutput", title: "Digital Output" },
    { key: "AnalogInput", title: "Analog Input" },
    { key: "DigitalInput", title: "Digital Input" },
  ]);

  //DOb
  const [date, setDate] = React.useState<date | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  //DOJ
  const [joinDate, setJoinDate] = React.useState<date | undefined>(undefined);
  const [joinOpen, setJoinOpen] = React.useState(false);

  //Regex
  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  // var MobileNo_pattern = new RegExp(/^[0]?[789]\d{9}$/);
  // var number_regex = new RegExp(/^[0-9]*$/);
  const regex = /^[0-9]*$/;

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();

      getCountry();
      // getOmegaIdList();
    }, [])
  );

  useEffect(() => {
    var cultiTotal = 0;
    var waterTotal = 0;
    var cropsTotal = 0;
    for (let i = 0; i < tableData.length; i++) {
      cultiTotal = cultiTotal + Number(tableData[i].data.cultivated_area);
      waterTotal = waterTotal + Number(tableData[i].data.water_demand);
      cropsTotal = cropsTotal + Number(tableData[i].data.crops);
    }
    setCultivatedTotal(cultiTotal);
    setWaterTotal(waterTotal);
    setcropsTotal(cropsTotal);
  }, [tableData]);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");

    // console.log("jsonValue:", jsonValue);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue);
    if (projValue != null) {
      // console.log("project id select in subzone create", projValue);
      setProjectId(projValue);
      getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse==>", userDetailsresponse);

      setloginId(userDetailsresponse.email_id);
      setEmpCode(userDetailsresponse.employee_code);
      setProjectId(userDetailsresponse.project_selected);

      getOmegaDependentByProjectId(userDetailsresponse.project_selected);
    }
  };
  // Country Get Api
  const getCountry = async () => {
    setIsLoading(true);
    try {
      var countryData = JSON.stringify({
        module: "country_master",
        data: {
          active: 1,
        },
        relation: [],
      });
      const countryNameResponse = await postData(
        countryData,
        "masters/getMasterList"
      );
      setIsLoading(false);
      var code = countryNameResponse.code;
      if (code == 1) {
        // console.log("Country list====>", countryNameResponse.data);
        // setCountryLength(countryNameResponse.data.length);
        setCountryNameData(countryNameResponse.data);
      } else {
        setIsLoading(false);
        //alert(countryNameResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const customStyles = {
    rows: {
      style: {
        // maxWidth: 50,
        minHeight: "30px", // override the row height
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        backgroundColor: "#319ef0a3",
        minHeight: "40px",
        fontSize: 16,
        fontWeight: "600",
      },
    },
    headCells: {
      style: {
        // paddingLeft: '8px', // override the cell padding for head cells
        // paddingRight: '8px',
        justifyContent: "center",
        minHeight: "20px",

        // borderRightWidth: 2,
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          // fontSize: 16,
          // fontWeight: '600',
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",

        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          fontSize: 16,
          fontWeight: "400",
          // borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const columns = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];
  const columnsAnalog = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const columnDigitalInput = [
    // {
    //   name: "Sr.No",
    //   selector: "id",
    //   sortable: true
    // },
    {
      name: "Output number",
      // selector: row => !editInput ? row.project_name : <TextInput value='helllo'></TextInput>,
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Output Name",
      selector: (row) => row.name,
      sortable: true,
    },
    // {
    //   name: "Area",
    //   selector: "area",
    //   sortable: true
    // },
    // {
    //   name: "Water Demand",
    //   selector: "water_demand",
    //   sortable: true
    // },
  ];

  const renderOmegaIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.device_name}</Text>
        )}
        {item.id === OmegavalueId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderValveIdListItems = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.name}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.name}</Text>}
        {item.id === ValveId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const renderCountryList = (item: any) => {
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.country}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.country}</Text>}
        {item.id === countryValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderStateListItems = (item) => {
    // // console.log("State id item", item.id);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.state}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.state}</Text>}
        {item.id === stateValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderCityListItems = (item) => {
    // console.log("city item name-", item.city);
    return (
      <View style={styles.item}>
        {isAdd == true && <Text style={styles.textItem}>{item.city}</Text>}
        {isAdd == false && <Text style={styles.textItem}>{item.city}</Text>}
        {item.id === cityValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderTahasilListItems = (item) => {
    // console.log("tahasil item name-", item.tahasil_name);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.tahasil_name}</Text>
        )}
        {item.id === tahasilValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };
  const renderVillageListItems = (item) => {
    // console.log("village item name-", item.village_name);
    return (
      <View style={styles.item}>
        {isAdd == true && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {isAdd == false && (
          <Text style={styles.textItem}>{item.village_name}</Text>
        )}
        {item.id === VillageValId && (
          <Icon style={styles.icon} color="black" name="Safety" size={20} />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    console.log('here');

    if (FarmerName == "") {
      setFarmerNameError("Please enter first name");
    }
    //Password
    if (Password == "") {
      setPasswordError("Please enter password ");
    }

    //mobile number
    if (MobileNumber == "") {
      setMobileNumError("Please enter mobile number");
    }
    console.log('here 2');


    if (FarmerName != "" && EmailId != "" && MobileNumber != "") {
      console.log('here 3');

      createFarmerProfileData();
      setfieldnameError("")
      setCultivatedError("")
      setWaterError("")
      setCropsError("")
      setValvesError("")
      setaddressError("")
      setsave(true);
    }
  };

  //Get details when click on omega id
  const getOmegaDetails = async (deviceId) => {
    setIsLoading(true);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails payload", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getOmegaDetails"
      );
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {

        // // console.log(" Farmer details api valves====>", OmegaDetailsResponse.data[0].valves);

        setShouldShow(false);
        setOmegaDetails(OmegaDetailsResponse.data[0].valves);
        setAnalogInput(OmegaDetailsResponse.data[0].analogInput);
        setDigitalInput(OmegaDetailsResponse.data[0].digitalInput);
      } else {
        setIsLoading(false);
        // //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const getZoneSubZone = async (deviceId) => {
    setIsLoading(true);
    setzone("");
    setSubZone("");
    setDevice("");
    setdeviceRelationship(0);
    setdeviceRelationshipId();
    // console.log("deviceIddeviceId", deviceId);
    try {
      var OmegaDetails = JSON.stringify({
        device_id: deviceId,
      });
      // console.log("OmegaDetails", OmegaDetails);
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "masters/getZoneSubZone"
      );
      // console.log("farmer zone details api====>", OmegaDetailsResponse);
      setIsLoading(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        if (OmegaDetailsResponse.data[0].type == 1) {
          setDevice(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
        if (OmegaDetailsResponse.data[0].type == 2) {
          setzone(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
        if (OmegaDetailsResponse.data[0].type == 3) {
          setSubZone(OmegaDetailsResponse.data[0].name);
          setdeviceRelationship(OmegaDetailsResponse.data[0].type);
          setdeviceRelationshipId(OmegaDetailsResponse.data[0].id);
        }
      } else {
        setIsLoading(false);
        //alert(OmegaDetailsResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Get Dependent omega  by project id
  const getOmegaDependentByProjectId = async (project_id) => {
    // console.log("project_idproject_id", project_id);

    setIsLoading(true);
    try {
      var OmegaIdData = JSON.stringify({
        column: "project_id",
        value: project_id,
        module: "devices_master",
      });
      // console.log("getdependent omega id payload", OmegaIdData);
      const OmegaIdList = await postData(
        OmegaIdData,
        "masters/getDependentMasterList"
        // "masters/getOmegaFromBermad"
      );
      setIsLoading(false);
      var code = OmegaIdList.code;
      if (code == 1) {
        // console.log("Bermad zone Omega list api====>", OmegaIdList.data);

        setOmegaIdData(OmegaIdList.data);
      } else {
        setIsLoading(false);
        //alert(OmegaIdList.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  //Get Omega  id from Bermad  autoload
  // Get dependent state by country
  const getDependentCountryMaster = async (country_id) => {
    // console.log("country_id masters", country_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "country_id",
        value: country_id,
        module: "state_master",
      });
      const dependentCountryDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCountryDataResponse.code;
      if (code == 1) {
        console.log(
          "getDependentCountryMaster======",
          dependentCountryDataResponse.data
        );
        setIsLoading(false);
        // setCountryLength(dependentCountryDataResponse.data.length);
        setStateNameData(dependentCountryDataResponse.data);
      } else {
        setIsLoading(false);
        //alert(dependentCountryDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent City by state
  const getDependentStateMaster = async (state_id) => {
    // console.log("state_id masters===", state_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "state_id",
        value: state_id,
        module: "city_master",
      });
      const dependentStateDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentStateDataResponse.code;
      if (code == 1) {
        console.log(
          "dependentStateDataResponse======",
          dependentStateDataResponse.data
        );
        // setCountryLength(dependentStateDataResponse.data.length);
        setCityNameData(dependentStateDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentStateDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent Tahasil by City
  const getDependentCityMaster = async (city_id) => {
    // console.log("city_id masters===", city_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "city_id",
        value: city_id,
        module: "tahasil_master",
      });
      const dependentCityDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentCityDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentCityDataResponse.data.length);
        setTahasilNameData(dependentCityDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentCityDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  // Get dependent village by Tahasil
  const getDependentTahasilMaster = async (tahasil_id) => {
    // console.log("tahasil_id masters===", tahasil_id);
    setIsLoading(true);
    try {
      var dependentData = JSON.stringify({
        column: "tahasil_id",
        value: tahasil_id,
        module: "village_master",
      });
      const dependentTahasilDataResponse = await postData(
        dependentData,
        "masters/getDependentMasterList"
      );
      setIsLoading(false);
      var code = dependentTahasilDataResponse.code;
      if (code == 1) {

        // setCountryLength(dependentTahasilDataResponse.data.length);
        setVillageNameData(dependentTahasilDataResponse.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //alert(dependentTahasilDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //Farmer Profile Add Api
  const createFarmerProfileData = async () => {
    // console.log("table data and first data", tableData, firstdata);

    setIsLoading(true);
    try {
      var farmerMasterData = JSON.stringify({
        module: "farmer_master",
        data: {
          project_id: projectId,
          farmer_name: FarmerName,
          mobile_number: MobileNumber,
          email_id: EmailId,
          country_id: countryValId,
          state_id: stateValId,
          city_id: cityValId,
          tahasil_id: tahasilValId,
          village_id: VillageValId,
          pincode: PinCode,
          address: Address,
          username: MobileNumber,
          password: Password,
          active: 1,
        },
        // id: ProjectIdOmega,
        submodule: "farmer_details",
        submodule_field_name: "farmer_id",
        submoduledata: tableData.length > 0 ? tableData : firstdata,
        uniqueNo: {
          field_name: "farmer_id",
          transactionId: 5,
        },
      });
      // console.log("Farmer payload===", farmerMasterData);
      const FaarmerProfileCreateResponse = await postData(
        farmerMasterData,
        "masters/farmerCreateUpdate"
      );

      setIsLoading(false);
      setsave(false);
      var code = FaarmerProfileCreateResponse.code;
      if (code == 1) {
        setPopupMessage("Record Created Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        // setIsVisible(false);
        // console.log("Farmer profile added", FaarmerProfileCreateResponse.data);
        navigation.navigate("FarmerProfile");
        setsave(false);
      } else {
        setIsLoading(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(FaarmerProfileCreateResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  console.log('tableData', tableData);


  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;

  return (
    <View style={{ paddingBottom: 5 }}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />

      <View style={styles.container}>
        <View style={isMobile ? { flexDirection: "row", width: "100%", marginTop: "-15%" } : { flexDirection: "row", width: "100%", marginTop: "0%" }}>
          <View style={{ flex: 4, margin: 10 }}>
            <Text style={styles.lableText}>
              Profile {">"}
              <Text onPress={() => navigation.navigate("FarmerProfile")}>
                {" "}
                Farmer Profile{" "}
              </Text>{" "}
              {">"} Create Farmer Profile
            </Text>
          </View>
          {/* <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={styles.addCountry}
            onPress={() => setIsVisible(true)}
          >
            <Text style={styles.btnText}>
              <Icon name="plus" size={15} color="#fff" /> Add Users
            </Text>
          </TouchableOpacity>
        </View> */}
        </View>
        {/* <ScrollView
        style={[styles.card, styles.shadowProp]}
        contentContainerStyle={{ flexGrow: 1 }}
      > */}
        <View style={[styles.card, { flexGrow: 1 }]}>
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "white",
                borderRadius: 8,
                paddingVertical: 0,
                paddingHorizontal: 25,
              },
            ]}
          >
            <View
              style={isMobile ? {
                // flex: 1,
                // justifyContent: "center",
                // // alignItems: "center",
                // flexDirection: "row",
              } : {
                flex: 1,
                justifyContent: "center",
                // alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View style={isMobile ? { flex: 1, marginTop: 10, marginLeft: "0%" } : { flex: 1, marginTop: 10, marginLeft: "10%" }}>
                <View
                  style={{
                    // backgroundColor: "#F2F2F2",
                    borderRadius: 10,
                    padding: 10,
                    // margin: 7,
                  }}
                >
                  <Text style={styles.lableText}>Farmer Name</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter Farmer name"
                    value={FarmerName}
                    onChangeText={(value) => {
                      setFarmerNameValid(false);
                      setFarmerName(value);
                      if (value.length == 0) {
                        setFarmerNameError("Please enter first name");
                      } else {
                        setFarmerNameValid(true);
                        setFarmerNameError("");
                      }
                    }}
                  />
                  {/* <View> */}
                  <Text style={[styles.error_text]}>{FarmerNameError}</Text>
                  {/* </View> */}
                  <Text style={styles.lableText}> Mobile no.</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter Mobile Number"
                    maxLength={10}
                    keyboardType={"numeric"}
                    onChangeText={(value) => {
                      setMobileNumberValid(false);
                      setMobileNumber(value);
                      if (value.length == 0) {
                        setMobileNumError("Please enter mobile number");
                      } else if (!regex.test(value)) {
                        setMobileNumError("Please enter number only");
                      } else if (value.length > 0 && value.length <= 9) {
                        setMobileNumError("Please enter 10 digit number");
                      } else {
                        setMobileNumberValid(true);
                        setMobileNumError("");
                      }
                    }}
                  />
                  <Text style={[styles.error_text]}>{mobileNumError}</Text>
                  <Text style={styles.lableText}>Email Id</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter Email id"
                    value={EmailId}
                    onChangeText={(value) => {
                      setEmailIdValid(false);
                      setEmailId(value.replace(/\s/g, ""));
                      // if (value.length == 0) {
                      //   setEmailIdError("Please enter email id");
                      // } else if (!Email_Id_pattern.test(value)) {
                      //   setEmailIdError("Please enter valid email id  ");
                      // } else {
                      //   setEmailIdValid(true);
                      //   setEmailIdError("");
                      // }
                    }}
                  />
                  <Text style={[styles.error_text]}>{emailIdError}</Text>
                  <Text style={styles.lableText}>Login</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter login id"
                    value={MobileNumber}
                    editable={false}
                    onChangeText={(value) => {
                      setloginIdValid(false);

                      setloginId(value);

                      if (Email_Id_pattern.test(value)) {
                        setloginIdValid(true);

                        //   setButtonDisabled(false);
                      }
                    }}
                  />
                  <Text style={styles.lableText}>Password</Text>
                  <TextInput
                    placeholder="Enter Password"
                    placeholderColor="#F0F0F0"
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    secureTextEntry={true}
                    onChangeText={(value) => {
                      setPasswordValid(false);
                      setPassword(value);
                      if (value.length == 0) {
                        setPasswordError("Please enter password");
                      } else {
                        setPasswordError("");
                        setPasswordValid(true);
                      }
                    }}
                  />{" "}
                  <Text style={[styles.error_text]}>{passwordError}</Text>
                  <Text style={styles.lableText}>Pin</Text>
                  <TextInput
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    maxLength={6}
                    placeholder="Enter Pincode"
                    onChangeText={(value) => {
                      setPinCode(value);
                    }}
                  />
                  <Text style={[styles.error_text]}>{pinError}</Text>
                  {/* <Text style={styles.lableText}>Omega Id</Text>
                  <View style={{ flexDirection: 'row', }} >
                    <View style={{ width: '100%' }}>
                      <Dropdown
                        ref={dropdownRefOmegaiD}

                        style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={OmegaIdData}
                        search
                        maxHeight={300}
                        labelField="device_name"
                        valueField="device_name"
                        placeholder={!isFocus ? "Select Omega Id" : "...."}
                        searchPlaceholder="Search..."
                        value={OmegaIdValue}
                        onChange={(item) => {
                          setOmegaIdValue(item.device_name);
                          setOmegaValueiD(item.id);
                          setOmegaError('');
                          getOmegaDetails(item.id);
                          // console.log("omega id value---", item.id);
                        }}
                        renderItem={renderOmegaIdListItems}
                      />
                      <Text style={[styles.error_text]}>{OmegaError}</Text>

                    </View>
                    <View style={{ marginLeft: -100 }}>
                      {isLoading ? (
                        <ActivityIndicator
                          //visibility of Overlay Loading Spinner
                          color="#1DA1F2"
                          visible={isLoading}
                          //Text with the Spinner
                          textContent={"Loading..."}
                          size={40}
                        //  //Text style of the Spinner Text
                        //  textStyle={styles.spinnerTextStyle}
                        />
                      ) : (
                        <IconRefresh name="refresh" size={30} onPress={() => getOmegaIdList()} />
                      )}
                    </View>
                  </View> */}
                </View>
                {/* <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >

              </View>
              <View
                style={{
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >

              </View> */}
              </View>
              <View
                style={isMobile ? {
                  // flex: 1,
                  marginTop: -20,
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  // margin: 7,
                } : {
                  flex: 1,
                  marginTop: 53,
                  // backgroundColor: "#F2F2F2",
                  borderRadius: 10,
                  padding: 10,
                  margin: 7,
                }}
              >
                <View
                  style={
                    {
                      // backgroundColor: "#F2F2F2"
                    }
                  }
                >
                  <Text style={styles.lableText}>Country</Text>
                  <Dropdown
                    ref={dropdownRefcountry}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CountryNameData}
                    search
                    maxHeight={300}
                    labelField="country"
                    valueField="country"
                    placeholder={"Select Country"}
                    searchPlaceholder="Search..."
                    value={countryValue}
                    onChange={(item) => {
                      // setCityNameDataValid(false)
                      setCountryValue(item.country);
                      getDependentCountryMaster(item.id);
                      // console.log("country id user master add", item);
                    }}
                    renderItem={renderCountryList}
                  />
                  <Text style={[styles.error_text]}>{countryError}</Text>
                </View>
                <View
                  style={
                    {
                      // backgroundColor: "#F2F2F2"
                    }
                  }
                >
                  <Text style={styles.lableText}>State</Text>
                  <Dropdown
                    ref={dropdownRefState}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={StateNameData}
                    search
                    maxHeight={300}
                    labelField="state"
                    valueField="state"
                    placeholder={!isFocus ? "Select State" : "...."}
                    searchPlaceholder="Search..."
                    value={stateValue}
                    onChange={(item) => {
                      // setStateNameDataValid(false)

                      setStateValue(item.state);
                      getDependentStateMaster(item.id);
                      // console.log("state create value---add", item.id);
                    }}
                    renderItem={renderStateListItems}
                  />
                  <Text style={[styles.error_text]}>{stateError}</Text>
                </View>
                <View>
                  <Text style={styles.lableText}>District / City</Text>
                  <Dropdown
                    ref={dropdownRefCity}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={CityNameData}
                    search
                    maxHeight={300}
                    labelField="city"
                    valueField="city"
                    placeholder={!isFocus ? "Select City" : "...."}
                    searchPlaceholder="Search..."
                    value={cityValue}
                    onChange={(item) => {
                      setCityValue(item.city);
                      getDependentCityMaster(item.id);
                      // console.log("city create value---add", item.id);
                    }}
                    renderItem={renderCityListItems}
                  />
                  <Text style={[styles.error_text]}>{cityError}</Text>
                </View>
                <View>
                  <Text style={styles.lableText}>Tahasil</Text>
                  <Dropdown
                    ref={dropdownRefTahasil}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={TahasilNameData}
                    search
                    maxHeight={300}
                    labelField="tahasil_name"
                    valueField="tahasil_name"
                    placeholder={!isFocus ? "Select Tahasil" : "...."}
                    searchPlaceholder="Search..."
                    value={tahasilValue}
                    onChange={(item) => {
                      setTahasilValue(item.tahasil_name);
                      getDependentTahasilMaster(item.id);

                    }}
                    renderItem={renderTahasilListItems}
                  />
                  <Text style={[styles.error_text]}>{tahasilError}</Text>
                </View>
                <View>
                  <Text style={styles.lableText}>Village</Text>
                  <Dropdown
                    ref={dropdownRefVillage}
                    style={isMobile ? {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 2,
                      height: 40,
                      outlineStyle: "none",
                      padding: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    } : styles.dropdown}
                    placeholderStyle={styles.placeholderStyle}
                    selectedTextStyle={styles.selectedTextStyle}
                    inputSearchStyle={styles.inputSearchStyle}
                    iconStyle={styles.iconStyle}
                    data={VillageNameData}
                    search
                    maxHeight={300}
                    labelField="village_name"
                    valueField="village_name"
                    placeholder={!isFocus ? "Select Village" : "...."}
                    searchPlaceholder="Search..."
                    value={VillageValue}
                    onChange={(item) => {
                      setVillageValue(item.village_name);
                      // console.log("village value---", item.id);
                    }}
                    renderItem={renderVillageListItems}
                  />
                  <Text style={[styles.error_text]}>{villageError}</Text>
                </View>
                {/* <View >
                  <Text style={styles.lableText}>Khasara number </Text>
                  <TextInput
                    style={isMobile ?  {
                      borderColor: "#c3c3c3",
                      width: "100%",
                      borderWidth: 1,
                      borderRadius: 4,
                      color: "#000",
                      padding: 10,
                      marginTop: 4,
                      marginBottom: 6,
                    } : styles.input}
                    placeholder="Enter Khasara Number"
                    keyboardType={"numeric"}
                    onChangeText={(value) => {
                      setKhasaraValid(false);
                      setKhasaraNum(value);
                      if (value.length == 0) { setkhasaraError("Please enter Khasara number") }
                      else if (!regex.test(value)) {
                        setkhasaraError("Please enter number only")
                      }
                      // else if (value.length > 0 && value.length <= 9) {
                      //   setkhasaraError('Please enter 10 digit number')
                      // }
                      else {
                        setKhasaraValid(true);
                        setkhasaraError('')
                      }
                    }}

                  />
                  <Text style={[styles.error_text]}>{khasaraError}</Text>
                </View> */}
                <Text style={styles.lableText}>Address </Text>
                <TextInput
                  multiline
                  numberOfLines={2}
                  style={isMobile ? {
                    borderColor: "#c3c3c3",
                    width: "100%",
                    borderWidth: 1,
                    borderRadius: 4,
                    color: "#000",
                    padding: 10,
                    marginTop: 4,
                    marginBottom: 6,
                  } : [styles.input, { marginBottom: 0 }]}
                  // onChangeText={onChangeNumber}
                  // value={number}
                  placeholder="Enter Address"
                  maxLength={500}
                  keyboardType="numeric"
                  onChangeText={(value) => {
                    setAddress(value);
                  }}
                />{" "}
              </View>
            </View>

            {/* <View style={{ marginTop: 20 }}>
            <TabView
              navigationState={{ index, routes }}
              renderScene={renderScene}
              onIndexChange={setIndex}
              initialLayout={{ width: layout.width }}
            />
          </View> */}
            {/* </ScrollView> */}
          </View>
        </View>
      </View>
      {shouldShow ? null : (
        <>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <Pressable
              onPress={() => setActiveTab(0)}
              style={{
                backgroundColor: ActiveTab == 0 ? "#F6F9F9" : "white",
                borderTopLeftRadius: ActiveTab == 0 ? 10 : 0,
                borderTopRightRadius: ActiveTab == 0 ? 10 : 0,
                paddingHorizontal: 5,
                width: 150,
                alignItems: "center",
              }}
            >
              <Text style={{ marginVertical: 10 }}>Farmer Field Details</Text>
            </Pressable>
          </View>
          <View style={{ backgroundColor: "#F6F9F9" }}>
            <ScrollView horizontal>
              <DataTableNEW
                style={{ flex: 1, padding: 10, backgroundColor: "#F6F9F9" }}
              >
                <DataTableNEW.Header style={styles.tableHeader}>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Field Id
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Field name
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.12, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Cultivated area(Ha)
                    </Text>{" "}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Water demand
                    </Text>
                    <Text> (m³)</Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Crops
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Address
                    </Text>
                  </DataTableNEW.Title>
                  {/* <DataTableNEW.Title style={{ flex: 0.40, justifyContent: 'center' }}><Text style={{
                  fontSize: 14,
                  fontWeight: '600'
                }}>System Info</Text></DataTableNEW.Title> */}
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Omega
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Valve
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.09, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Zone
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.09, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Sub Zone
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.09, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Device
                    </Text>
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.12, justifyContent: "center" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      Action
                    </Text>
                  </DataTableNEW.Title>
                </DataTableNEW.Header>

                {tableData.map((items, index) => {
                  return (
                    <DataTableNEW.Row style={{ flex: 1 }}>
                      <DataTableNEW.Cell
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        {items.data.field_id}
                      </DataTableNEW.Cell>
                      <DataTableNEW.Cell
                        style={{
                          flex: 0.08,
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>
                          <TextInput
                            value={items.data.khasara_number}
                            style={{
                              justifyContent: "center",
                              borderColor: "black",
                              width: "99%",
                              height: 40,
                              // outlineStyle: 'none',
                              textAlign: "center",
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                            onChangeText={(text) => {
                              setfieldnameError("");

                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: text,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: p.data.water_demand,
                                        crops: p.data.crops,
                                        address: p.data.address,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        devices: p.data.devices,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              )
                            }
                            }
                          />
                          <Text style={[styles.error_text1]}>{fieldnameError}</Text>
                        </View>

                      </DataTableNEW.Cell>


                      <DataTableNEW.Cell
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <TextInput
                            value={items.data.cultivated_area}
                            keyboardType="numeric"
                            style={{
                              justifyContent: "center",
                              alignSelf: "flex-start",
                              borderColor: "black",
                              // outlineStyle: 'none',
                              textAlign: "center",
                              width: "99%",
                              height: 40,
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                            onChangeText={(text) => {
                              setCultivatedError("")
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: text,
                                        water_demand: p.data.water_demand,
                                        crops: p.data.crops,
                                        address: p.data.address,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              );
                            }}
                          />
                          <Text style={[styles.error_text1]}>{CultivatedError}</Text>
                        </View>
                      </DataTableNEW.Cell>

                      <DataTableNEW.Cell
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <TextInput
                            value={items.data.water_demand}
                            keyboardType="numeric"
                            style={{
                              justifyContent: "center",
                              alignSelf: "flex-start",
                              borderColor: "black",
                              // outlineStyle: 'none',
                              textAlign: "center",
                              width: "99%",
                              height: 40,
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                            onChangeText={(text) => {
                              setWaterError("")
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: text,
                                        crops: p.data.crops,
                                        address: p.data.address,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        devices: p.data.devices,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              )
                            }
                            }
                          />
                          <Text style={[styles.error_text1]}>{WaterError}</Text>
                        </View>
                      </DataTableNEW.Cell>

                      <DataTableNEW.Cell
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <TextInput
                            value={items.data.crops}
                            keyboardType="numeric"
                            style={{
                              justifyContent: "center",
                              alignSelf: "flex-start",
                              borderColor: "black",
                              // outlineStyle: 'none',
                              textAlign: "center",
                              width: "99%",
                              height: 40,
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                            onChangeText={(text) => {
                              setCropsError("")
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: p.data.water_demand,
                                        crops: text,
                                        address: p.data.address,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        devices: p.data.devices,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              )
                            }}
                          />
                          <Text style={[styles.error_text1]}>{CropsError}</Text>
                        </View>
                      </DataTableNEW.Cell>

                      <DataTableNEW.Cell
                        style={{ flex: 0.08, justifyContent: "center" }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <TextInput
                            value={items.data.address}
                            style={{
                              justifyContent: "center",
                              alignSelf: "flex-start",
                              borderColor: "black",
                              // outlineStyle: 'none',
                              textAlign: "center",
                              width: "99%",
                              height: 40,
                              borderWidth: 1,
                              backgroundColor: "transparent",
                            }}
                            onChangeText={(text) => {
                              setaddressError("")
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: p.data.water_demand,
                                        crops: p.data.crops,
                                        address: text,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        devices: p.data.devices,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              )
                            }}
                          />
                          <Text style={[styles.error_text1]}>{addressError}</Text>
                        </View>
                      </DataTableNEW.Cell>
                      <DataTableNEW.Cell
                        style={{
                          flex: 0.4,
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <Dropdown
                            ref={dropdownRefOmegaiD}
                            style={{
                              borderColor: "#c3c3c3",
                              width: "95%",
                              flex: 0.09,
                              borderWidth: 1,
                              borderRadius: 2,
                              justifyContent: "center",
                              height: 40,
                              marginBottom: 2,
                              padding: 10,
                            }}
                            placeholderStyle={{
                              fontSize: 12,
                            }}
                            selectedTextStyle={{
                              fontSize: 12,
                            }}
                            inputSearchStyle={{
                              // height: 40,
                              fontSize: 13,
                            }}
                            iconStyle={styles.iconStyle}
                            data={OmegaIdData}
                            search
                            maxHeight={300}
                            labelField="device_name"
                            valueField="device_name"
                            placeholder={"Select"}
                            searchPlaceholder="Search..."
                            value={items.data.OmegaIdValue}
                            onChange={async (item) => {
                              setOmegaError("");
                              getOmegaDetails(item.id);
                              getZoneSubZone(item.id);
                              setzone("");
                              setSubZone("");
                              setDevice("");
                              setdeviceRelationship(0);
                              setdeviceRelationshipId();
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: p.data.water_demand,
                                        crops: p.data.crops,
                                        address: p.data.address,
                                        zone: "",
                                        subZone: "",
                                        devices: "",
                                        OmegaIdValue: item.device_name,
                                        device_id: item.id,
                                        ValveName: p.data.ValveName,
                                        valve_index: p.data.valve_index,
                                        valve_id: p.data.ValveId,
                                        device_relationship: "",
                                        device_relationship_referance_id: "",
                                      },
                                    }
                                    : p
                                )
                              );
                              try {
                                var OmegaDetails = JSON.stringify({
                                  device_id: item.id,
                                });
                                // console.log("OmegaDetails", OmegaDetails);
                                const OmegaDetailsResponse = await postData(
                                  OmegaDetails,
                                  "masters/getZoneSubZone"
                                );
                                setIsLoading(false);
                                var code = OmegaDetailsResponse.code;
                                if (code == 1) {

                                  if (OmegaDetailsResponse.data[0].type == 2) {
                                    settableData(
                                      tableData.map((p) =>
                                        p.data.field_id === items.data.field_id
                                          ? {
                                            ...p,
                                            data: {
                                              farmer_id: p.data.farmer_id,
                                              field_id: p.data.field_id,
                                              khasara_number:
                                                p.data.khasara_number,
                                              cultivated_area:
                                                p.data.cultivated_area,
                                              water_demand: p.data.water_demand,
                                              crops: p.data.crops,
                                              address: p.data.address,
                                              zone: OmegaDetailsResponse.data[0]
                                                .name,
                                              subZone: "",
                                              devices: "",
                                              OmegaIdValue: item.device_name,
                                              device_id: item.id,
                                              ValveName: p.data.ValveName,
                                              valve_index: p.data.valve_index,
                                              valve_id: p.data.ValveId,
                                              device_relationship:
                                                OmegaDetailsResponse.data[0]
                                                  .type,
                                              device_relationship_referance_id:
                                                OmegaDetailsResponse.data[0].id,
                                            },
                                          }
                                          : p
                                      )
                                    );
                                  }
                                  if (OmegaDetailsResponse.data[0].type == 3) {
                                    setSubZone(OmegaDetailsResponse.data[0].name);
                                    setdeviceRelationship(
                                      OmegaDetailsResponse.data[0].type
                                    );
                                    setdeviceRelationshipId(
                                      OmegaDetailsResponse.data[0].id
                                    );
                                    settableData(
                                      tableData.map((p) =>
                                        p.data.field_id === items.data.field_id
                                          ? {
                                            ...p,
                                            data: {
                                              farmer_id: p.data.farmer_id,
                                              field_id: p.data.field_id,
                                              khasara_number:
                                                p.data.khasara_number,
                                              cultivated_area:
                                                p.data.cultivated_area,
                                              water_demand: p.data.water_demand,
                                              crops: p.data.crops,
                                              address: p.data.address,
                                              zone: "",
                                              subZone:
                                                OmegaDetailsResponse.data[0]
                                                  .name,
                                              devices: "",
                                              OmegaIdValue: item.device_name,
                                              device_id: item.id,
                                              ValveName: p.data.ValveName,
                                              valve_index: p.data.valve_index,
                                              valve_id: p.data.ValveId,
                                              device_relationship:
                                                OmegaDetailsResponse.data[0]
                                                  .type,
                                              device_relationship_referance_id:
                                                OmegaDetailsResponse.data[0].id,
                                            },
                                          }
                                          : p
                                      )
                                    );
                                  }
                                  if (OmegaDetailsResponse.data[0].type == 1) {
                                    setDevice(OmegaDetailsResponse.data[0].name);
                                    setdeviceRelationship(
                                      OmegaDetailsResponse.data[0].type
                                    );
                                    setdeviceRelationshipId(
                                      OmegaDetailsResponse.data[0].id
                                    );
                                    settableData(
                                      tableData.map((p) =>
                                        p.data.field_id === items.data.field_id
                                          ? {
                                            ...p,
                                            data: {
                                              farmer_id: p.data.farmer_id,
                                              field_id: p.data.field_id,
                                              khasara_number:
                                                p.data.khasara_number,
                                              cultivated_area:
                                                p.data.cultivated_area,
                                              water_demand: p.data.water_demand,
                                              crops: p.data.crops,
                                              address: p.data.address,
                                              zone: "",
                                              subZone: "",
                                              devices:
                                                OmegaDetailsResponse.data[0]
                                                  .name,
                                              OmegaIdValue: item.device_name,
                                              device_id: item.id,
                                              ValveName: p.data.ValveName,
                                              valve_index: p.data.valve_index,
                                              valve_id: p.data.ValveId,
                                              device_relationship:
                                                OmegaDetailsResponse.data[0]
                                                  .type,
                                              device_relationship_referance_id:
                                                OmegaDetailsResponse.data[0].id,
                                            },
                                          }
                                          : p
                                      )
                                    );
                                  }
                                } else {
                                  setIsLoading(false);
                                  //alert(OmegaDetailsResponse.message);
                                }
                              } catch (error) {
                                setIsLoading(false);
                                // console.log(error);
                              }
                            }}
                            // onChangeText={(text) => settableData(tableData.map(p =>
                            //   p.field_id === items.id
                            //     ? { ...p, address: text }
                            //     : p
                            // ))}
                            renderItem={renderOmegaIdListItems}
                          />
                          <Text style={[styles.error_text1]}>{OmegaError}</Text>
                        </View>
                        <View style={{ padding: 6, backgroundColor: '#f6f9f9' }}>

                          <Dropdown
                            ref={dropdownRefOmegaiD}
                            style={{
                              borderColor: "#c3c3c3",
                              width: "95%",
                              flex: 0.09,
                              marginLeft: 5,
                              borderWidth: 1,
                              borderRadius: 2,
                              justifyContent: "center",
                              alignSelf: "flex-start",
                              height: 40,
                              padding: 10,
                            }}
                            placeholderStyle={{
                              fontSize: 12,
                            }}
                            selectedTextStyle={{
                              fontSize: 12,
                            }}
                            inputSearchStyle={{
                              height: 40,
                              fontSize: 13,
                            }}
                            iconStyle={styles.iconStyle}
                            data={OmegaDetails}
                            search
                            maxHeight={300}
                            labelField="name"
                            valueField="name"
                            placeholder={!isFocus ? "Select" : "...."}
                            searchPlaceholder="Search..."
                            value={items.data.ValveName}
                            onChange={(item) => {
                              setValvesError("")
                              setValveName("");
                              settableData(
                                tableData.map((p) =>
                                  p.data.field_id === items.data.field_id
                                    ? {
                                      ...p,
                                      data: {
                                        farmer_id: p.data.farmer_id,
                                        field_id: p.data.field_id,
                                        khasara_number: p.data.khasara_number,
                                        cultivated_area: p.data.cultivated_area,
                                        water_demand: p.data.water_demand,
                                        crops: p.data.crops,
                                        address: p.data.address,
                                        zone: p.data.zone,
                                        subZone: p.data.subZone,
                                        OmegaIdValue: p.data.OmegaIdValue,
                                        device_id: p.data.device_id,
                                        ValveName: item.name,
                                        valve_index: p.data.valve_index,
                                        valve_id: item.id,
                                        device_relationship:
                                          p.data.device_relationship,
                                        device_relationship_referance_id:
                                          p.data
                                            .device_relationship_referance_id,
                                      },
                                    }
                                    : p
                                )
                              );
                            }}
                            renderItem={renderValveIdListItems}
                          />
                          <Text style={[styles.error_text1]}>{valvesError}</Text>
                        </View>

                        <TextInput
                          value={items.data.zone}
                          style={{
                            marginHorizontal: 1,
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            width: "15%",
                            marginLeft: 5,
                            borderRadius: 2,
                            marginTop: 2,
                            height: 40,
                            padding: 10,
                            textAlign: "center",
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                        // onChangeText={(text) => setzone(text)}
                        />

                        <TextInput
                          value={items.data.subZone}
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            width: "15%",
                            marginLeft: 5,
                            borderRadius: 2,
                            height: 40,
                            padding: 10,
                            textAlign: "center",
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                        // onChangeText={(text) => setSubZone(text)}
                        />
                        <TextInput
                          value={items.data.devices}
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            width: "15%",
                            marginLeft: 5,
                            borderRadius: 2,
                            height: 40,
                            padding: 10,
                            textAlign: "center",
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                        // onChangeText={(text) => setSubZone(text)}
                        />
                      </DataTableNEW.Cell>
                      <DataTableNEW.Cell
                        style={{ flex: 0.12, justifyContent: "center" }}
                      >
                        {/* <TouchableOpacity
                          onPress={() => {
                          }}
                        >
                          <IconEdit
                            name="pencil"
                            size={13}
                            color="#fff"
                            style={{
                              padding: 6,
                              backgroundColor: "#08588E",
                              borderRadius: 12,
                            }}
                          />
                        </TouchableOpacity> */}
                        <TouchableOpacity
                          onPress={() => {
                            updateTableData(
                              tableData.length > 0
                                ? tableData.length < 9
                                  ? `F0${tableData.length + 1}0${tableData.length + 1
                                  }`
                                  : `F${tableData.length + 1}${tableData.length + 1
                                  }`
                                : "F0101"
                            );
                          }}
                          style={{ marginHorizontal: 2 }}
                        >
                          <IconEdit
                            name="plus"
                            size={13}
                            color="#fff"
                            style={{
                              padding: 6,
                              backgroundColor: "#7CA53C",
                              borderRadius: 12,
                            }}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() =>
                            settableData(
                              tableData.filter(
                                (item) =>
                                  item.data.field_id != items.data.field_id
                              )
                            )
                          }
                        >
                          <IconEdit
                            name="trash-o"
                            size={14}
                            color="#fff"
                            style={{
                              padding: 6,
                              backgroundColor: "#FF0000",
                              borderRadius: 12,
                            }}
                          />
                        </TouchableOpacity>
                      </DataTableNEW.Cell>
                    </DataTableNEW.Row>
                  );
                })}
                {tableData.length > 0 ? null : (
                  <DataTableNEW.Row style={{ flex: 1 }}>
                    <DataTableNEW.Cell
                      style={{ flex: 0.08, justifyContent: "center" }}
                    >
                      {tableData.length > 0
                        ? tableData.length < 9
                          ? `F0${tableData.length + 1}0${tableData.length + 1}`
                          : `F${tableData.length + 1}${tableData.length + 1}`
                        : "F0101"}
                    </DataTableNEW.Cell>
                    <DataTableNEW.Cell
                      style={{
                        flex: 0.08,
                        alignSelf: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>
                        <TextInput
                          value={fieldname}
                          style={{
                            justifyContent: "center",
                            borderColor: "black",
                            width: "99%",
                            height: 40,
                            // outlineStyle: 'none',
                            textAlign: "center",
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                          onChangeText={(text) => {
                            setfieldname(text)
                            setfieldnameError("")
                          }

                          }
                        />
                        <Text style={[styles.error_text1]}>{fieldnameError}</Text>
                      </View>
                    </DataTableNEW.Cell>


                    <DataTableNEW.Cell
                      style={{ flex: 0.08, justifyContent: "center" }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>
                        <TextInput
                          value={Cultivated}
                          keyboardType="numeric"
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            textAlign: "center",
                            width: "99%",
                            height: 40,
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                          onChangeText={(text) => {
                            setCultivated(text);
                            setCultivatedError("")
                          }}
                        />
                        <Text style={[styles.error_text1]}>{CultivatedError}</Text>
                      </View>

                    </DataTableNEW.Cell>

                    <DataTableNEW.Cell
                      style={{ flex: 0.08, justifyContent: "center" }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>

                        <TextInput
                          value={Water}
                          keyboardType="numeric"
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            textAlign: "center",
                            width: "99%",
                            height: 40,
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                          onChangeText={(text) => {
                            setWater(text)
                            setWaterError('')
                          }
                          }
                        />
                        <Text style={[styles.error_text1]}>{WaterError}</Text>
                      </View>
                    </DataTableNEW.Cell>

                    <DataTableNEW.Cell
                      style={{ flex: 0.08, justifyContent: "center" }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>

                        <TextInput
                          value={Crops}
                          keyboardType="numeric"
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            textAlign: "center",
                            width: "99%",
                            height: 40,
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                          onChangeText={(text) => {
                            setCrops(text)
                            setCropsError("")
                          }}
                        />
                        <Text style={[styles.error_text1]}>{CropsError}</Text>
                      </View>
                    </DataTableNEW.Cell>

                    <DataTableNEW.Cell
                      style={{ flex: 0.08, justifyContent: "center" }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>

                        <TextInput
                          value={address}
                          style={{
                            justifyContent: "center",
                            alignSelf: "flex-start",
                            borderColor: "black",
                            // outlineStyle: 'none',
                            textAlign: "center",
                            width: "99%",
                            height: 40,
                            borderWidth: 1,
                            backgroundColor: "transparent",
                          }}
                          onChangeText={(text) => {
                            setaddress(text)
                            setaddressError("")
                          }}
                        />
                        <Text style={[styles.error_text1]}>{addressError}</Text>
                      </View>
                    </DataTableNEW.Cell>
                    <DataTableNEW.Cell
                      style={{
                        flex: 0.4,
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>

                        <Dropdown
                          ref={dropdownRefOmegaiD}
                          style={{
                            borderColor: "#c3c3c3",
                            width: "95%",
                            flex: 0.09,
                            borderWidth: 1,
                            borderRadius: 2,
                            alignSelf: "center",
                            height: 40,
                            marginBottom: 2,
                            padding: 10,
                          }}
                          placeholderStyle={{
                            fontSize: 12,
                          }}
                          selectedTextStyle={{
                            fontSize: 12,
                          }}
                          inputSearchStyle={{
                            height: 40,
                            fontSize: 13,
                          }}
                          iconStyle={styles.iconStyle}
                          data={OmegaIdData}
                          search
                          maxHeight={300}
                          labelField="device_name"
                          valueField="device_name"
                          placeholder={!isFocus ? "Select Device Name" : "...."}
                          searchPlaceholder="Search..."
                          value={OmegaIdValue}
                          onChange={(item) => {
                            setOmegaIdValue(item.device_name);
                            setOmegaValueiD(item.id);
                            setOmegaError("");
                            getOmegaDetails(item.id);
                            setProjectIdOmega(item.project_id);
                            getZoneSubZone(item.id);
                            // console.log("omega id value---", item.id);
                          }}
                          renderItem={renderOmegaIdListItems}
                        />
                        <Text style={[styles.error_text1]}>{OmegaError}</Text>
                      </View>
                      <View style={{ padding: 6, backgroundColor: "#f6f9f9" }}>

                        <Dropdown
                          ref={dropdownRefOmegaiD}
                          style={{
                            borderColor: "#c3c3c3",
                            width: "95%",
                            flex: 0.09,
                            marginLeft: 5,
                            borderWidth: 1,
                            borderRadius: 2,
                            alignSelf: "flex-start",
                            height: 40,
                            padding: 10,
                          }}
                          placeholderStyle={{
                            fontSize: 12,
                          }}
                          selectedTextStyle={{
                            fontSize: 12,
                          }}
                          inputSearchStyle={{
                            height: 40,
                            fontSize: 13,
                          }}
                          iconStyle={styles.iconStyle}
                          data={OmegaDetails}
                          search
                          maxHeight={300}
                          labelField="name"
                          valueField="name"
                          placeholder={!isFocus ? "Select Valve" : "...."}
                          searchPlaceholder="Search..."
                          value={ValveName}
                          onChange={(item) => {
                            setValveName(item.name);
                            setValveIndex(item.index);
                            setValveID(item.id);
                            setValvesError("");
                            // console.log("Valve id value---", item);
                          }}
                          renderItem={renderValveIdListItems}
                        />
                        <Text style={[styles.error_text1]}>{valvesError}</Text>
                      </View>
                      <TextInput
                        value={zone}
                        placeholder="zone"
                        editable={false}
                        style={{
                          marginHorizontal: 1,
                          justifyContent: "center",
                          alignSelf: "flex-start",
                          borderColor: "black",
                          // outlineStyle: 'none',
                          width: "22%",
                          marginLeft: 5,
                          borderRadius: 2,
                          marginTop: 2,
                          height: 40,
                          padding: 10,
                          textAlign: "center",
                          borderWidth: 1,
                          backgroundColor: "transparent",
                        }}
                      // onChangeText={(text) => setzone(text)}
                      />
                      <TextInput
                        value={subZone}
                        placeholder="Sub zone"
                        editable={false}
                        style={{
                          justifyContent: "center",
                          alignSelf: "flex-start",
                          borderColor: "black",
                          // outlineStyle: 'none',
                          width: "17%",
                          marginLeft: 5,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          textAlign: "center",
                          borderWidth: 1,
                          backgroundColor: "transparent",
                        }}
                      // onChangeText={(text) => setSubZone(text)}
                      />
                      <TextInput
                        value={devices}
                        placeholder="Device"
                        editable={false}
                        style={{
                          justifyContent: "center",
                          alignSelf: "flex-start",
                          borderColor: "black",
                          // outlineStyle: 'none',
                          width: "18%",
                          marginLeft: 5,
                          borderRadius: 2,
                          height: 40,
                          padding: 10,
                          textAlign: "center",
                          borderWidth: 1,
                          backgroundColor: "transparent",
                        }}
                      // onChangeText={(text) => setSubZone(text)}
                      />
                      {/* <Dropdown
                    ref={dropdownRefOmegaiD}
                    style={{
                      borderColor: "#c3c3c3",
                      width: "24%",
                      marginLeft: 5,
                      flex:0.09,
                      borderWidth: 1,
                      borderRadius: 2,
                      alignSelf: 'flex-start',
                      height: 40,
                      padding: 10,
                    }}
                    placeholderStyle={{
                      fontSize: 12,
                    }}
                    selectedTextStyle={{
                      fontSize: 12,
                    }}
                    inputSearchStyle={{
                      height: 40,
                      fontSize: 13,
                    }}
                    iconStyle={styles.iconStyle}
                    data={OmegaDetails}
                    search
                    maxHeight={300}
                    labelField="name"
                    valueField="name"
                    placeholder={!isFocus ? "Select" : "...."}
                    searchPlaceholder="Search..."
                    value={OmegaIdValue}
                    onChange={(item) => {
                      setValveName(item.name);
                      setValveID(item.id);
                      setValveError('');
                      // console.log("Valve id value---", item.id);
                    }}
                    renderItem={renderValveIdListItems}
                  />
                  <Dropdown
                    ref={dropdownRefOmegaiD}
                    style={{
                      borderColor: "#c3c3c3",
                      width: "24%",
                      flex:0.09,
                      marginLeft: 5,
                      borderWidth: 1,
                      borderRadius: 2,
                      alignSelf: 'flex-start',
                      height: 40,
                      padding: 10,
                    }}
                    placeholderStyle={{
                      fontSize: 12,
                    }}
                    selectedTextStyle={{
                      fontSize: 12,
                    }}
                    inputSearchStyle={{
                      height: 40,
                      fontSize: 13,
                    }}
                    iconStyle={styles.iconStyle}
                    data={OmegaDetails}
                    search
                    maxHeight={300}
                    labelField="name"
                    valueField="name"
                    placeholder={!isFocus ? "Select" : "...."}
                    searchPlaceholder="Search..."
                    value={OmegaIdValue}
                    onChange={(item) => {
                      setValveName(item.name);
                      setValveID(item.id);
                      setValveError('');
                      // console.log("Valve id value---", item.id);
                    }}
                    renderItem={renderValveIdListItems}
                  /> */}
                    </DataTableNEW.Cell>
                    <DataTableNEW.Cell
                      style={{
                        flex: 0.12,
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity onPress={() => { }}>
                        <IconEdit
                          name="pencil"
                          size={13}
                          color="#fff"
                          style={{
                            padding: 6,
                            backgroundColor: "#08588E",
                            borderRadius: 12,
                          }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setCultivatedTotal(
                            CultivatedTotal + parseInt(Cultivated)
                          ),
                            setWaterTotal(WaterTotal + parseInt(Water)),
                            setcropsTotal(cropsTotal + parseInt(Crops)),
                            updateTableData(
                              tableData.length > 0
                                ? tableData.length < 9
                                  ? `F0${tableData.length + 1}0${tableData.length + 1
                                  }`
                                  : `F${tableData.length + 1}${tableData.length + 1
                                  }`
                                : "F0101"
                            );
                        }}
                        style={{ marginHorizontal: 2 }}
                      >
                        <IconEdit
                          name="plus"
                          size={13}
                          color="#fff"
                          style={{
                            padding: 6,
                            backgroundColor: "#7CA53C",
                            borderRadius: 12,
                          }}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setfieldname(""),
                            setCultivated(""),
                            setWater(""),
                            setCrops(""),
                            setaddress(""),
                            setzone(""),
                            setSubZone(""),
                            setDevice(""),
                            setOmegaValueiD(""),
                            setValveID(""),
                            setValveName(null),
                            setValveIndex(null),
                            setOmegaIdValue(null),
                            setdeviceRelationship(0),
                            setdeviceRelationshipId();
                        }}
                      >
                        <IconEdit
                          name="trash-o"
                          size={14}
                          color="#fff"
                          style={{
                            padding: 6,
                            backgroundColor: "#FF0000",
                            borderRadius: 12,
                          }}
                        />
                      </TouchableOpacity>
                    </DataTableNEW.Cell>
                  </DataTableNEW.Row>
                )}

                <DataTableNEW.Header style={{ backgroundColor: "transparent" }}>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total: {CultivatedTotal ? CultivatedTotal : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total:{WaterTotal ? WaterTotal : 0}{" "}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total:{cropsTotal ? cropsTotal : 0}{" "}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.08, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                  <DataTableNEW.Title
                    style={{ flex: 0.1, justifyContent: "center" }}
                  >
                    Total: {tableData ? tableData.length : 0}
                  </DataTableNEW.Title>
                </DataTableNEW.Header>
              </DataTableNEW>
            </ScrollView>
          </View>
        </>
      )}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginHorizontal: 20,
          marginVertical: 10,
        }}
      >
        <TouchableOpacity
          style={styles.saveBtn}
          onPress={() => {
            setfirstdata([
              {
                data: {
                  field_id: "F0101",
                  khasara_number: fieldname,
                  cultivated_area: Cultivated,
                  water_demand: Water,
                  crops: Crops,
                  address: address,
                  zone: zone,
                  subZone: subZone,
                  devices: devices,
                  device_id: OmegavalueId,
                  valve_id: ValveId,
                  OmegaIdValue: OmegaIdValue,
                  ValveName: ValveName,
                  valve_index: ValveIndex,
                  device_relationship: deviceRelationship,
                  device_relationship_referance_id: deviceRelationshipId,
                },
              },
            ]),
              CheckEnteredData();
          }}
        >
          <Text style={styles.btnText}>Save</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.saveBtn}
          onPress={() => navigation.navigate("FarmerProfile")}
        >
          <Text style={styles.btnText}>Cancel</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },
  tableHeader: {
    // backgroundColor: "#DCDCDC",
    backgroundColor: "#319ef0a3",
  },
  genderNames: {
    margin: 7,
  },
  statusNames: {
    margin: 7,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "white",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    height: "100%",
    marginRight: "10%",
    marginTop: "0%",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    // marginRight: 10,
    // marginRight: 1,
    width: "70%",
  },
  input: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 4,
    color: "#000",
    padding: 10,
    marginTop: 4,
    marginBottom: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  addCountry: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "70%",
    // marginTop: 20,
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "20%",
    marginRight: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  card: {
    // backgroundColor: "white",
    // borderRadius: 8,
    // paddingVertical: 45,
    // paddingHorizontal: 25,
    // width: "100%",
    // flex: 1,
    // marginVertical: 10,
    // paddingBottom: "48%",

    width: "100%",
    flex: 1,
    marginVertical: 0,
  },
  error_text1: {
    color: "red",
    fontSize: 10,
    fontWeight: "600",
    // width: "70%",
    marginLeft: 0,
    // marginBottom: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 25,
  },

  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "70%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    outlineStyle: "none",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 0,
    // fontSize: 21,
  },
});
